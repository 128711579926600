.square {
    width: 50px !important;
    height: 50px !important;
    border-width: 0 !important;
    transition: background-color 0.3s ease, color 0.3s ease !important;
}

.board {
    max-width: 600px !important;
    margin: 0 auto !important;
}

.border {
    border: initial !important;
}

.win-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.win-screen-content {
    color: #fff;
    background-color: #28a745;
    padding: 30px;
    border-radius: 10px;
}