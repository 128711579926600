.square {
    width: 50px !important;
    height: 50px !important;
    background-color: blue;
    border-color: black;
    border: solid;
    transition: background-color ease 3s;
}

.board {
    max-width: fit-content;
}